import React from "react";
import { useNavigate } from 'react-router-dom';

import ForgotPasswordController from "../../Controllers/AuthenticationPages/ForgotPasswordController";
import HeaderNavBarLogin from '../../../Components/HeaderNavBarLogin';
import Footer from '../../../Components/Footer';

const ForgotPasswordScreen = () => {
  const {
    email,
    setEmail,
    handleSubmit,
    error,
    loading,
    success,
    handleResetPassword
  } = ForgotPasswordController();

  const navigate = useNavigate();

  return (
    <>
      <HeaderNavBarLogin />
      <div className="safe-area2" style={{ maxWidth: 550, margin: '45px auto', padding: '20px' }}>
        <div className="container">
          <div>
            {!success ? (
              <>
                <div className="">
                  <div className="title-headings" style={{ textAlign: "center", marginBottom: '20px' }}>Forgot Password</div>
                  <div className="subtitle" style={{ textAlign: "center", marginBottom: '20px' }}>
                    Please enter your email address to receive a reset code to create a new password.
                  </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                  <input
                    className="input"
                    type="email"
                    placeholder="Please Enter Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{
                      width: 'calc(100% - 110px)',
                      padding: '8px',
                      fontSize: '14px',
                      borderRadius: '19px',
                      border: '1px solid #ccc',
                      marginRight: '10px',
                      height: '30px',
                      paddingLeft:'20px'
                    }}
                  />
                  <button 
                    className="submit-button" 
                    onClick={handleSubmit} 
                    disabled={loading} 
                    style={{
                      width: '50px', 
                      padding: '0px 5px',
                      fontSize: '14px', 
                      backgroundColor: '#4474b1', 
                      color: '#fff', 
                      border: 'none', 
                      borderRadius: '19px',
                      cursor: 'pointer', 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center',
                      height: '45px',
                    }}
                  >
                    {loading && <span className="spinner" style={{ marginRight: '5px' }}></span>}
                    <span className="submit-button-text">Submit</span>
                  </button>
                </div>

                {error && <p className="error-text" style={{ color: 'red', textAlign: 'center' }}>{error}</p>}

                <div className="action-container" style={{ textAlign: 'center', marginTop: '20px' }}>
                  <button onClick={() => navigate('/login')} className="link-button" style={{ color: '#007bff', textDecoration: 'underline' }}>Back to Login</button>
                </div>

              </>
            ) : (
              <>
                <div className="">
                  <div className="title-headings" style={{ textAlign: "center", marginBottom: '20px' }}>Forgot Password</div>
                  <div className="subtitle" style={{ textAlign: "center", marginBottom: '20px' }}>
                    Reset code sent to your email. Please check your inbox for your reset code and click below to reset your password.
                  </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                  <button 
                    className="submit-button" 
                    onClick={handleResetPassword} 
                    style={{
                      width: '30%', 
                      padding: '8px', 
                      fontSize: '14px', 
                      backgroundColor: '#4474b1', 
                      color: '#fff', 
                      border: 'none', 
                      borderRadius: '19px',
                      cursor: 'pointer', 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center',
                      height: '45px',
                    }}
                  >
                    <span className="submit-button-text">Reset Password</span>
                  </button>
                </div>

                <div className="action-container" style={{ textAlign: 'center', marginTop: '20px' }}>
                  <button onClick={() => navigate('/login')} className="link-button" style={{ color: '#007bff', textDecoration: 'underline' }}>Back to Login</button>
                </div>
              </>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ForgotPasswordScreen;
