import React, { useState, useEffect } from 'react';
import CustomDropdown from '../../Components/CustomDropdown';
import TimeDropdown from '../../Components/TimeDropdown';

const CampInventoryForm = ({ formData, handleInputChange, handleAddItem, loading, error, success, setFormData, selectedCategory }) => {
    const [timeError, setTimeError] = useState('');
    const [operatingStartHour, setOperatingStartHour] = useState('');
    const [operatingStartMinute, setOperatingStartMinute] = useState('');
    const [operatingStartMeridiem, setOperatingStartMeridiem] = useState('AM');
    const [operatingEndHour, setOperatingEndHour] = useState('');
    const [operatingEndMinute, setOperatingEndMinute] = useState('');
    const [operatingEndMeridiem, setOperatingEndMeridiem] = useState('PM');
    const [operatingDay, setOperatingDay] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useEffect(() => {
        if (success) {
            // Clear all fields when success message is set
            setFormData({
                program_name: '',
                description: '',
                address: '',
                info_link: '',
                availability_time: []
            });
            setOperatingDay('');
            setOperatingStartHour('');
            setOperatingStartMinute('');
            setOperatingEndHour('');
            setOperatingEndMinute('');
        }
    }, [success, setFormData]);

    const convertTo24HourFormat = (hour, minute, meridiem) => {
        let formattedHour = parseInt(hour, 10);
        const formattedMinute = parseInt(minute, 10);

        if (meridiem === 'PM' && formattedHour !== 12) {
            formattedHour += 12;
        } else if (meridiem === 'AM' && formattedHour === 12) {
            formattedHour = 0;
        }

        return formattedHour * 60 + formattedMinute; // Return total minutes since midnight
    };

    const checkOverlap = (start1, end1, start2, end2) => {
        const startTime1 = convertTo24HourFormat(...start1.split(' '));
        const endTime1 = convertTo24HourFormat(...end1.split(' '));
        const startTime2 = convertTo24HourFormat(...start2.split(' '));
        const endTime2 = convertTo24HourFormat(...end2.split(' '));

        return startTime1 < endTime2 && startTime2 < endTime1;
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setOperatingDay('');
        setOperatingStartHour('');
        setOperatingStartMinute('');
        setOperatingEndHour('');
        setOperatingEndMinute('');
    };

    const displayText = operatingStartHour && operatingEndHour
      ? `${operatingDay || 'Day'}: ${operatingStartHour || 'Start Time'}:${operatingStartMinute || '00'} ${operatingStartMeridiem || 'AM'} - ${operatingEndHour || 'End Time'}:${operatingEndMinute || '00'} ${operatingEndMeridiem || 'PM'}`
      : 'Select Day and Time';

    const handleAddOperatingHour = () => {
        setTimeError('');

        const formattedStartTime = `${operatingStartHour}:${operatingStartMinute} ${operatingStartMeridiem}`;
        const formattedEndTime = `${operatingEndHour}:${operatingEndMinute} ${operatingEndMeridiem}`;

        if (!operatingDay) {
            setTimeError('Please select a day.');
            return;
        }
        
        if (!operatingStartHour || !operatingStartMinute) {
            setTimeError('Please provide a valid start time.');
            return;
        }
        
        if (!operatingEndHour || !operatingEndMinute) {
            setTimeError('Please provide a valid end time.');
            return;
        }
        

        const start = convertTo24HourFormat(operatingStartHour, operatingStartMinute, operatingStartMeridiem);
        const end = convertTo24HourFormat(operatingEndHour, operatingEndMinute, operatingEndMeridiem);

        if (end <= start) {
            setTimeError('Start time must be earlier than end time.');
            return;
        }

        setFormData((prevData) => {
            const updatedAvailabilityTime = prevData.availability_time ? [...prevData.availability_time] : [];

            const isDuplicate = updatedAvailabilityTime.some(
                (hour) =>
                    hour.day === operatingDay &&
                    checkOverlap(hour.start_time, hour.end_time, formattedStartTime, formattedEndTime)
            );

            if (isDuplicate) {
                setTimeError('Duplicate entry.');
                return prevData; // Return prevData without changes to avoid setting state with error
            }

            return {
                ...prevData,
                availability_time: [
                    ...updatedAvailabilityTime,
                    { day: operatingDay, start_time: formattedStartTime, end_time: formattedEndTime },
                ],
            };
        });

        setIsDropdownOpen(false);
    };

    const handleAddItemWithValidation = () => {
        setTimeError('');
        if ((formData.availability_time || []).length === 0) {
            setTimeError('Please Add Availability Time.');
            return;
        }
        handleAddItem();
    };

    const getProgramNamePlaceholder = () => {
        switch (selectedCategory?.toLowerCase()) {
            case 'medical':
                return 'Program Name *';
            case 'jobs & training':
                return 'Organization Name *';
            case 'services':
                return 'Name *';
            default:
                return 'Program Name *';
        }
    };

    const [isDayDropdownOpen, setIsDayDropdownOpen] = useState(false);
    const toggleDayDropdown = () => {
        setIsDayDropdownOpen(prevState => !prevState);
    };

    const handleDaySelection = (day, isSelected) => {
        if (day === 'All') {
            if (isSelected) {
                // If "All" is selected, set operatingDay to include all days
                setOperatingDay(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']);
            } else {
                // If "All" is deselected, clear the operatingDay array
                setOperatingDay([]);
            }
        } else {
            setOperatingDay((prevDays) => {
                if (isSelected) {
                    // If a specific day is selected, add it to the array
                    return [...prevDays, day];
                } else {
                    // If a specific day is deselected, remove it from the array
                    return prevDays.filter((d) => d !== day);
                }
            });
        }
    };

    return (
        <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            flexDirection: 'column', 
            width: '70%',   
            maxWidth: '500px', 
            padding: '20px', 
            margin: '0 auto'
                }}>
            <div style={{ width: '100%', marginBottom: '10px' }}>
                <label style={{ display: 'block', marginBottom: '5px', fontSize: '14px' }}>Program Name<span className="star-color">*</span></label>
                <input
                    type="text"
                    placeholder={getProgramNamePlaceholder()}
                    value={formData.program_name}
                    onChange={(e) => handleInputChange('program_name', e.target.value)}
                    style={{ 
                        width: '100%', 
                        padding: '10px', 
                        fontSize: '14px', 
                        borderRadius: '4px', 
                        border: '1px solid #ccc', 
                        marginBottom: '10px' 
                    }}
                />
            </div>
            <div style={{ width: '100%', marginBottom: '10px' }}>
                <label style={{ display: 'block', marginBottom: '5px', fontSize: '14px' }}>Description<span className="star-color">*</span></label>
                <input
                    type="text"
                    placeholder="Enter Description"
                    value={formData.description}
                    onChange={(e) => handleInputChange('description', e.target.value)}
                    style={{ 
                        width: '100%', 
                        padding: '10px', 
                        fontSize: '14px', 
                        borderRadius: '4px', 
                        border: '1px solid #ccc', 
                        marginBottom: '10px' 
                    }}
                />
            </div>
            <div style={{ width: '100%', marginBottom: '10px' }}>
                <label style={{ display: 'block', marginBottom: '5px', fontSize: '14px' }}>Address<span className="star-color">*</span></label>
                <input
                    type="text"
                    placeholder="Enter Address"
                    value={formData.address}
                    onChange={(e) => handleInputChange('address', e.target.value)}
                    style={{ 
                        width: '100%', 
                        padding: '10px', 
                        fontSize: '14px', 
                        borderRadius: '4px', 
                        border: '1px solid #ccc', 
                        marginBottom: '10px' 
                    }}
                />
            </div>
            <div style={{ width: '100%', marginBottom: '10px' }}>
                <label style={{ display: 'block', marginBottom: '5px', fontSize: '14px' }}>Info Link</label>
                <input
                    type="text"
                    placeholder="Enter Info Link"
                    value={formData.info_link}
                    onChange={(e) => handleInputChange('info_link', e.target.value)}
                    style={{ 
                        width: '100%', 
                        padding: '10px', 
                        fontSize: '14px', 
                        borderRadius: '4px', 
                        border: '1px solid #ccc', 
                        marginBottom: '10px' 
                    }}
                />
            </div>

            <div style={{ 
                marginBottom: '10px',
                marginLeft: '0px', 
                width: '100%', 
            }}>
                <label style={{ 
                    display: 'block', 
                    marginBottom: '5px', 
                    fontSize: '14px', 
                    width: '100%' 
                }}>
                    Operating Hours<span className="star-color">*</span>
                </label>
                <div 
                    onClick={toggleDropdown}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        padding: '10px',
                        cursor: 'pointer',
                        backgroundColor: '#fff',
                        boxShadow: 'none',
                        width: '100%', 
                        maxWidth: '100%', 
                        justifyContent: 'space-between',
                    }}
                >
                    <span style={{ 
                        flex: 1, 
                        fontSize: '14px', 
                        color: '#333', 
                        textAlign: 'left', 
                    }}>
                        {displayText}
                    </span>
                    <img
                        src={require('../../Assets/arrow-up.png')}
                        alt="Toggle Dropdown"
                        style={{
                            width: '15px',
                            height: '15px',
                            transition: 'transform 0.3s',
                            transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)'
                        }}
                    />
                </div>

                {isDropdownOpen && (
                    <div className="dropdown-content-ci-reg" style={{ 
                        width: '100%', 
                        maxWidth: '100%', 
                        marginTop: '10px' 
                    }}>
                        <div className="dropdown-item-ci">
                            <div 
                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} 
                                onClick={toggleDayDropdown}
                            >
                                <label className="label">Select Day</label>
                                <img
                                    src={require('../../Assets/arrow-up.png')}
                                    className={`arrow-icon-ci ${isDayDropdownOpen ? 'rotate-icon-ci' : ''}`}
                                    alt="Toggle Days"
                                    style={{ width: '13px', height: '13px', marginRight: 10 }}
                                />
                            </div>
                            {isDayDropdownOpen && (
                                <div className="checkbox-group">
                                    <div className="checkbox-item" style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            id="All"
                                            value="All"
                                            checked={operatingDay.length === 7} 
                                            onChange={(e) => handleDaySelection('All', e.target.checked)}
                                            style={{ marginRight: '10px' }}
                                        />
                                        <label htmlFor="All">All</label>
                                    </div>
                                    {[
                                        { id: 'Mon', label: 'Mon', value: 'Mon' },
                                        { id: 'Tue', label: 'Tue', value: 'Tue' },
                                        { id: 'Wed', label: 'Wed', value: 'Wed' },
                                        { id: 'Thu', label: 'Thu', value: 'Thu' },
                                        { id: 'Fri', label: 'Fri', value: 'Fri' },
                                        { id: 'Sat', label: 'Sat', value: 'Sat' },
                                        { id: 'Sun', label: 'Sun', value: 'Sun' },
                                    ].map(day => (
                                        <div key={day.id} className="checkbox-item" style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="checkbox"
                                                id={day.id}
                                                value={day.value}
                                                checked={operatingDay.includes(day.value)}
                                                onChange={(e) => handleDaySelection(e.target.value, e.target.checked)}
                                                style={{ marginRight: '10px' }}
                                            />
                                            <label htmlFor={day.id}>{day.label}</label>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="dropdown-item-ci" style={{ width: '100%' }}>
                            <label className="label">Start Time</label>
                            <TimeDropdown
                                selectedHour={operatingStartHour}
                                selectedMinute={operatingStartMinute}
                                selectedMeridiem={operatingStartMeridiem}
                                onHourChange={setOperatingStartHour}
                                onMinuteChange={setOperatingStartMinute}
                                onMeridiemChange={setOperatingStartMeridiem}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="dropdown-item-ci" style={{ width: '100%' }}>
                            <label className="label">End Time</label>
                            <TimeDropdown
                                selectedHour={operatingEndHour}
                                selectedMinute={operatingEndMinute}
                                selectedMeridiem={operatingEndMeridiem}
                                onHourChange={setOperatingEndHour}
                                onMinuteChange={setOperatingEndMinute}
                                onMeridiemChange={setOperatingEndMeridiem}
                                style={{ width: '100%' }}
                            />
                        </div>
                        {timeError && <p style={{ color: 'red', fontSize: '14px', marginTop: '10px' }}>{timeError}</p>}
                        <button className="add-button" onClick={handleAddOperatingHour} style={{ width: '100%' }}>
                            Add Days
                        </button>
                    </div>
                )}
            </div>

            {formData?.availability_time?.length > 0 && (
                <div style={{ marginTop: '20px', width: '100%' }}>
                    {formData.availability_time.map((item, index) => (
                        item.day.map((day, dayIndex) => (
                            <p key={`${index}-${dayIndex}`} style={{ margin: '5px 0' }}>
                                {day} : {item.start_time} - {item.end_time}
                            </p>
                        ))
                    ))}
                </div>
            )}

            <button
                onClick={handleAddItemWithValidation}
                style={{ padding: '10px 20px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer', marginTop: '10px' }}
                disabled={loading}
            >
                {loading ? 'Adding Item...' : 'Add Item'}
            </button>

            {error && <p style={{ color: 'red', fontSize: '14px', marginTop: '10px' }}>{error}</p>}
            {success && <p style={{ color: 'green', fontSize: '14px', marginTop: '10px' }}>{success}</p>}
        </div>
    );
};

export default CampInventoryForm;
