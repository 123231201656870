// src/views/NFPPage/NFPInventoryAddScreen.js
import React, { useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useNFPInventoryAddController from '../Controllers/NFPInventoryAddController';
import HeaderNavBar from '../../Components/HeaderNavBar';
// import CustomFooter from '../../components/CustomFooter';
// import LocationCapture from '../../components/LocationCapture';
// import './NFPInventoryAddScreen.css';
import Loader from '../../Components/Loader';
import foodIcon from '../../Assets/fork-and-spoon.png'
import clothesIcon from '../../Assets/casual-t-shirt-.png'
import customerIcon from '../../Assets/New-home.png'
import housingIcon from '../../Assets/New-home.png'
import serviceIcon from '../../Assets/trolley.png'
import arrowIcon from '../../Assets/upload.png'
import medicalIcon from '../../Assets/first-aid-kit.png'
import jobIcon from '../../Assets/internship.png'
import hygieneIcon from '../../Assets/liquid-soap.png'
import closeIcon from '../../Assets/close.png'
import CampInventoryForm from './CampInventoryForm';
import arrowRight from '../../Assets/right-arrow.png';
import circularArrow from '../../Assets/icons/left-arrow-in-circular-icon.png';

const NFPInventoryAddScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { latitude, longitude } = location.state || {};
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');

  const {
    formData,
    handleInputChange,
    addItemToInventory,
    loading,
    error,
    success,
    setError,
    setSuccess,
    categories,
    updateLocation,
    handleLocationError,
    handleCityNameUpdate,
    cityName,
    setFormData
  } = useNFPInventoryAddController(latitude, longitude,selectedCategory,selectedSubCategory);

  const [modalVisible, setModalVisible] = useState(false);
  const [originalSubCategory, setOriginalSubCategory] = useState('');

  const handleCategorySelect = (category) => {
    console.log('category:', category);
    if (categories[category]?.length > 0) {
      console.log('categories[selectedCategory]:', categories[category])
      if (selectedCategory !== category) {
        setSelectedCategory(category);
        handleInputChange('category', category);
        setSelectedSubCategory(''); // Reset selectedSubCategory when a new category is selected
        setOriginalSubCategory('');
      } else {
        setOriginalSubCategory(selectedSubCategory);
      }
      setModalVisible(true);
    } else {
      console.log('categories[selectedCategory] else:', categories[category])
      if (selectedCategory !== category) {
        setSelectedCategory(category);
        handleInputChange('category', category);
        setSelectedSubCategory(''); // Reset selectedSubCategory when a new category is selected
        setOriginalSubCategory('');
      } else {
        setOriginalSubCategory(selectedSubCategory);
      }
    }
  };

  const handleSubCategorySelect = (subCategory) => {
    setSelectedSubCategory(subCategory);
    handleInputChange('subCategory', subCategory);
    setModalVisible(false);
  };

  const closeModalWithoutSelection = () => {
    setModalVisible(false);
    if (originalSubCategory !== selectedSubCategory) {
      setSelectedSubCategory('');
    }
  };

  const categoryImages = {
    "Food": foodIcon,
    "Housing": housingIcon,
    "Services": serviceIcon,
    "Supplies": serviceIcon,
    "Clothes": clothesIcon,
    "Medical": medicalIcon,
    "Jobs & Training": jobIcon,
    "Hygiene": hygieneIcon,
    // Add more categories and their corresponding images here
  };

  const renderBreadcrumb = () => {
    let breadcrumb = selectedCategory;
    if (selectedSubCategory) {
      breadcrumb += ` > ${selectedSubCategory}`;
    }
    return breadcrumb;
  };

  const handleAddItem = async () => {
    console.log("selectedCategory in inventory",selectedCategory);
    setError('');
    if (!selectedCategory) {
      setError("Select Category");
      return;
    }
    // if(!selectedSubCategory){
    //     setError("Select Sub Category");
    //     return;
    // }
    await addItemToInventory();
    if (!error) {
      // Only reset itemName and itemQuantity after successful addition
      handleInputChange('itemName', '');
      handleInputChange('itemQuantity', '');
    }
  };

  return (
    <>
      <HeaderNavBar />
      <div className="safe-area2">
        {/* <HeaderNavBar title="Inventory Add" alignment="flex-start" icon="/assets/profile-icon.png" onIconPress={() => history.push('/profile')} /> */}
        <div className="container">
          {/* <LocationCapture onLocation={updateLocation} onError={handleLocationError} onCityName={handleCityNameUpdate} /> */}
          <div className="header-container" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <img
              src={circularArrow}
              alt="Back"
              style={{
                cursor: 'pointer',
                height: '25px',
                width: '25px',
                marginRight: '15px',
              }}
              onClick={() => navigate('/NFPInventoryList')}
            />
            <h2 className="category-title" style={{ display: 'flex', alignItems: 'center',justifyContent:'center' }}>Select Category</h2>
          </div>

          <div className="categories-container">
            {Object.keys(categories).map((category, index) => (
              <div
                key={index}
                className={`category-card ${selectedCategory === category ? 'selected-category' : ''}`}
                onClick={() => handleCategorySelect(category)}
              >
                <img src={categoryImages[category]} className="category-image" alt={category} />
                <span className="category-text">{category}</span>
              </div>
            ))}
          </div>

          {modalVisible && (
            <div className="modal-container" onClick={closeModalWithoutSelection}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button onClick={closeModalWithoutSelection} className="cross-button">
                  <img src={closeIcon} className="cross-button-image" alt="Close" />
                </button>
                <h3 className="modal-title">Select Subcategory</h3>
                <div className="subcategories-container">
                  {selectedCategory && categories[selectedCategory].map((subCategory, index) => {
                    if (index % 2 === 0) {
                      return (
                        <div key={index} className="sub-category-row">
                          <div
                            className={`sub-category-card ${selectedSubCategory === subCategory.service_name ? 'selected-sub-category' : ''}`}
                            onClick={() => handleSubCategorySelect(subCategory.service_name)}
                          >
                            <span className="sub-category-text">{subCategory.service_name}</span>
                          </div>
                          {categories[selectedCategory][index + 1] && (
                            <div
                              className={`sub-category-card ${selectedSubCategory === categories[selectedCategory][index + 1].service_name ? 'selected-sub-category' : ''}`}
                              onClick={() => handleSubCategorySelect(categories[selectedCategory][index + 1].service_name)}
                            >
                              <span className="sub-category-text">{categories[selectedCategory][index + 1].service_name}</span>
                            </div>
                          )}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
          )}

          <p className="breadcrumb">{renderBreadcrumb()}</p>

          {selectedCategory?.toLowerCase() === 'medical' || selectedCategory?.toLowerCase() === 'jobs & training' || selectedCategory?.toLowerCase() === 'services' ? (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              {/* Medical And Job Category Input Form Component Integration */}
              <CampInventoryForm
                formData={formData}
                handleInputChange={handleInputChange}
                handleAddItem={handleAddItem}
                loading={loading}
                error={error}
                success={success}
                setFormData={setFormData}
                selectedCategory={selectedCategory}
              />
            </div>
          ) : (
            <div style={{ 
              width: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center', 
              alignItems: 'center' 
            }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '10px' }}>
                <div style={{ flexBasis: '56.66%' }}>
                  <input
                    placeholder="Item Name *"
                    value={formData.itemName}
                    onChange={(e) => handleInputChange('itemName', e.target.value)}
                    style={{ 
                      width: '100%', 
                      height: '40px',
                      borderRadius: '8px', 
                      padding: '0 20px',
                      backgroundColor: '#fff',

                    }}
                  />
                </div>
            
                <div style={{ flexBasis: '13.66%' }}>
                  <input
                    placeholder="Item Quantity *"
                    value={formData.itemQuantity}
                    onChange={(e) => handleInputChange('itemQuantity', e.target.value)}
                    type="number"
                    style={{ 
                      width: '100%', 
                      height: '40px',
                      borderRadius: '8px', 
                      padding: '0 20px',
                      backgroundColor: '#fff', 
                    }}
                  /> 
                </div>
            
                <div style={{ flexBasis: '13.66%' }}>
                  <button 
                    onClick={handleAddItem} 
                    disabled={loading} 
                    style={{ 
                      width: '100%', 
                      padding: '12px', 
                      fontSize: '14px', 
                      backgroundColor: '#007bff', 
                      color: '#fff', 
                      border: 'none', 
                      borderRadius: '8px', 
                      cursor: 'pointer' 
                    }}
                  >
                    <span>Add Item</span>
                    {loading && <Loader color="#fff" loading={loading} style={{ marginLeft: '8px' }} />}
                  </button>
                </div>
              </div>
            
              {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
              {success && <p style={{ color: 'green', marginTop: '10px' }}>{success}</p>}
            </div>
            
            
            
          )}
        </div>
        {/* <CustomFooter /> */}
      </div>
    </>
  );
};

export default NFPInventoryAddScreen;
